import './assets/css/App.css';

import {
  DEFAULT_MAIN_SUBTITLE,
  EXIT_PAGE_MESSAGE,
  SECOND_MAIN_TITLE,
  THANK_YOU_ALTERNATIVE_ROUTE,
  THANK_YOU_LENDER_LIST_ROUTE,
} from './lib/constants';
import {
  Disclaimer,
  MainForm,
  PageTitle,
  ThankYouAlternative,
  ThankYouLenderList,
  TopBarAndFooter,
  VWOComponent,
} from './components';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  useAnura,
  useExitConfirmation,
  useExternalScript,
  useJornaya,
  useTrustedForm,
} from './lib/hooks';

import { CustomPixelHelmet } from './components/CustomComponents';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

function App() {
  useExitConfirmation(EXIT_PAGE_MESSAGE);
  useJornaya();
  useTrustedForm();
  useAnura();
  useExternalScript(
    'https://rgr-js.s3.us-west-2.amazonaws.com/tracking.js',
    'tracking_env',
    process.env.REACT_APP_ENV
  );
  useExternalScript(
    '//cdn-4.convertexperiments.com/v1/js/10049462-100413977.js'
  );

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_WINDOWS);
    ReactGA.send({
      hitType: 'pageview',
      page: '/',
    });
    ReactGA.event({
      category: 'GWT Landing',
      action: 'GWT Landing',
      label: 'GWT Landing',
    });
  }, []);

  return (
    <div className="page">
      <PageTitle title={process.env.REACT_APP_TAB_NAME} />
      <CustomPixelHelmet />
      <Router>
      <div className="main-content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <TopBarAndFooter showNumber={true} />
                  <MainForm />
                  <div>
                    <div className="disclaimer">
                      <Disclaimer />
                    </div>
                    <TopBarAndFooter className="top-bar-and-footer__bottom-gray " />
                  </div>
                </>
              }
            />

            <Route
              path="/v2"
              element={
                <>
                  <TopBarAndFooter showNumber={true} />
                  <VWOComponent />
                  <MainForm
                    title={SECOND_MAIN_TITLE}
                    subtitle={DEFAULT_MAIN_SUBTITLE.toLowerCase()}
                  />
                  <div>
                    <div className="disclaimer">
                      <Disclaimer />
                    </div>
                    <TopBarAndFooter className="top-bar-and-footer__bottom-gray " />
                  </div>
                </>
              }
            />
            <Route
              path={THANK_YOU_LENDER_LIST_ROUTE}
              element={
                <>
                  <TopBarAndFooter className="top-bar-and-footer__bottom-gray " />
                  <ThankYouLenderList />
                </>
              }
            />
            <Route
              path={THANK_YOU_ALTERNATIVE_ROUTE}
              element={
                <>
                  <TopBarAndFooter />
                  <ThankYouAlternative />
                </>
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
