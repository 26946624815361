import './CustomLoaderLightBox.css';

import MoonLoader from 'react-spinners/MoonLoader';
import React from 'react';
import { useScrollToTop } from '../../../lib/hooks';

function CustomLoaderLightBox() {
  useScrollToTop();

  return (
    <div className="loading-state">
      <MoonLoader/>
    </div>
  );
}

export default CustomLoaderLightBox;
