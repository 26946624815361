import { useEffect } from 'react';

const useTrustedForm = () => {
  useEffect(() => {
    const field = 'xxTrustedFormCertUrl';
    const provideReferrer = false;
    const invertFieldSensitivity = false;

    const trustedFormSrc = `http${
      'https:' === document.location.protocol ? 's' : ''
    }://api.trustedform.com/trustedform.js?provide_referrer=${encodeURIComponent(
      provideReferrer
    )}&field=${encodeURIComponent(
      field
    )}&l=${new Date().getTime()}${Math.random()}&invert_field_sensitivity=${invertFieldSensitivity}&use_tagged_consent=true`;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = trustedFormSrc;

    document.body.appendChild(script);

    window.trustedFormCertIdCallback = function (certificateId) {
      window.trustedFormId = certificateId;
    };

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
      delete window.trustedFormCertIdCallback;
    };
  }, []);

  return null;
};

export default useTrustedForm;
