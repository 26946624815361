import { createContext, useContext, useState } from 'react';

const defaultState = {
  address: '',
  city: '',
  email: '',
  firstname: '',
  homeowner: 'yes',
  lastname: '',
  phone: '',
  projecttype: '',
  state: '',
  windowcount: '',
  zip: '',
  step: 1,
};

/*const defaultState = {
  address: '12 main',
  city: 'los angeles',
  email: `mail${String(
    Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)
  )}@gmail.com`,
  firstname: 'alex',
  homeowner: 'yes',
  lastname: 'sam',
  phone: `323214${String(
    Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)
  )}`,
  projecttype: 'replace',
  state: 'CA',
  windowcount: '3-5',
  zip: '90036',
  step: 3,
};*/

const FormDataContext = createContext();

export const useFormDataContext = () => useContext(FormDataContext);

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState(defaultState);

  const updateFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  const clearStorage = () => setFormData(defaultState);

  const updateStep = (step) => {
    setFormData({
      ...formData,
      step,
    });
  };

  return (
    <FormDataContext.Provider
      value={{ formData, updateFormData, updateStep, clearStorage }}
    >
      {children}
    </FormDataContext.Provider>
  );
};
