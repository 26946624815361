import './ThankYouAlternative.css';

import { useConditionalNavigation, useScrollToTop } from '../../lib/hooks';
import Disclaimer from '../Disclaimer/Disclaimer';
import GWTLogo from '../../assets/img/logo.png';
import parse from 'html-react-parser';
import { useLendersContext } from '../../lib/contexts';
import { useNavigate } from 'react-router-dom';

function ThankYouAlternative() {
  const { name, pixel } = useLendersContext();
  let { eLocalPhone } = useLendersContext();
  eLocalPhone = null;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  useConditionalNavigation(!name, '/');
  useScrollToTop();
  const parsedPixel = parse(pixel || '');

  return (
    <div className="thank-you-alternative__container">
      {pixel && (
        <div className="thank-you-alternative-lenders__pixel">
          {parsedPixel}
        </div>
      )}
      <div className="thank-you-alternative__box thank-you-alternative__main-box">
        <div className="thank-you-alternative__logo-container">
          <img
            src={GWTLogo}
            alt="Logo"
            className="thank-you-alternative__logo"
          />
        </div>

        <div className="thank-you-alternative__main-content">
          <div className="thank-you-alternative__main-content-title">
            Thank You
          </div>
          <div className="thank-you-alternative__main-content-text">
            Thank You for your request {name}! We are on our way to connecting
            you with exceptional window contractors in your area.
          </div>
          <div className="thank-you-alternative__main-content-text">
            Stay tuned as we match you with top-notch window contractors who
            will exceed your expectations and deliver outstanding results. Your
            dream windows are just around the corner!
          </div>
        </div>
      </div>
      <div className="thank-you-alternative__box thank-you-alternative__phone-box">
        {eLocalPhone ? (
          <>
            <div className="thank-you-alternative__main-content-title">
              Don&apos;t Wait Any Longer!
            </div>
            <div className="thank-you-alternative__main-content-text">
              Get in touch with our top-rated window contractors now.
            </div>
            <div className="thank-you-alternative__main-content-text">
              Call us now at{' '}
              <a
                href={`tel:${eLocalPhone}`}
                target="_blank"
                rel="noopener noreferrer"
                className="thank-you-alternative__phone-link"
              >
                {eLocalPhone || '888-888-8888'}
              </a>{' '}
              and let&apos;s get started on your window project today!
            </div>
          </>
        ) : (
          <>
            <div className="thank-you-alternative__main-content-title">
              Our team is working diligently to find the best window contractor
              for your needs.
            </div>
            <div className="thank-you-alternative__main-content-text">
              We appreciate your trust in us, and we&apos;re committed to
              helping you achieve your dream windows!
            </div>
          </>
        )}
      </div>
      <div className="thank-you-alternative__box thank-you-alternative__home-box">
        <div className="thank-you-alternative__main-content-title">
          Ready for more options?
        </div>
        <div className="thank-you-alternative__main-content-text">
          Return to our main form and discover even more window project
          opportunities.
        </div>
        <button
          type="button"
          className="thank-you-alternative__main-content-btn"
          onClick={handleClick}
        >
          Explore More Choices
        </button>
      </div>
      <div className="disclaimer" style={{ width: '100%' }}>
        <Disclaimer />
      </div>
    </div>
  );
}

export default ThankYouAlternative;
