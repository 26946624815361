import './CustomSubmit.css';

import { CustomModalContent } from '../index';
import { useDisclaimerContext } from '../../../lib/contexts';
import { useState } from 'react';

function CustomSubmit() {
  const { disclaimerContext } = useDisclaimerContext();
  const { partnerList, termsOfUse, privacyPolicy, arbitrate } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    partnerList: false,
    termsOfUse: false,
    privacyPolicy: false,
    arbitrate: false,
  });

  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  const click = (event) => {
    if (event.target?.id === 'ppTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: false,
        privacyPolicy: true,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.target?.id === 'touTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: true,
        privacyPolicy: false,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div className="custom-submit__container">
      <CustomModalContent
        title="Partner List"
        isOpen={isOpen.partnerList}
        onClose={() => closeModal('partnerList')}
        content={partnerList.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Arbitrate"
        isOpen={isOpen.arbitrate}
        onClose={() => closeModal('arbitrate')}
        content={arbitrate.data?.html}
        clickEvent={click}
        isHtml
      />
      <div>
        <label className="custom-submit__consent-text">
          By clicking the button below, you acknowledge, consent, and agree to
          our terms at the bottom of this page.
        </label>
        <button type="submit" className="custom-submit__btn">
          Continue
        </button>
        <label className="custom-submit__consent-text">
          {'By clicking "GET YOUR MATCHES" you authorize up to three '}
          <a
            className="custom-submit__button-as-link"
            href="#"
            onClick={(e) => {
              e.stopPropagation();
              openModal('partnerList');
            }}
          >
            Home Improvement Partners
          </a>
          {` to call you (including through automated means; e.g. autodialing, text
        and pre-recorded messaging) via telephone, mobile device (including SMS
        and MMS) and/or email, at the number you entered above, with offers
        about their products or services, even if your phone number is on any
        national or state "Do Not Call" list and you agree to our `}
          <a
            href="#"
            className="custom-submit__button-as-link"
            onClick={(e) => {
              e.stopPropagation();
              openModal('termsOfUse');
            }}
          >
            Terms of Use
          </a>
          {' and '}
          <a
            href="#"
            className="custom-submit__button-as-link"
            onClick={(e) => {
              e.stopPropagation();
              openModal('privacyPolicy');
            }}
          >
            Privacy Policy
          </a>
          . Message and data rates may apply. Your consent here is not based on
          a condition of purchase.
        </label>
      </div>

      <label htmlFor="leadid_tcpa_disclosure" style={{ display: 'none' }}>
        <input type="hidden" id="leadid_tcpa_disclosure" />
        By clicking the button below, you acknowledge, consent, and agree to our
        terms at the bottom of this page. By clicking {'"GET YOUR MATCHES"'} you
        authorize up to three{' '}
        <a href="#partners-list" className="inline-link">
          Home Improvement Partners
        </a>{' '}
        to call you (including through automated means; e.g. autodialing, text
        and pre-recorded messaging) via telephone, mobile device (including SMS
        and MMS) and/or email, at the number you entered above, with offers
        about their products or services, even if your phone number is on any
        national or state {'"Do Not Call"'} list and you agree to our{' '}
        <a href="#terms-of-use" className="inline-link">
          Terms of Use
        </a>{' '}
        and{' '}
        <a href="#privacy-policy" className="inline-link">
          Privacy Policy
        </a>
        . Message and data rates may apply. Your consent here is not based on a
        condition of purchase.
      </label>
    </div>
  );
}

export default CustomSubmit;
