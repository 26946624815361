import './TopBarAndFooter.css';

import GWTLogo from '../../assets/img/logo.png';
import { LOCAL_PHONE_NUMBER } from '../../lib/constants';
import { showELocalPhone } from '../../lib/utils';
import { useGeoLocationContext } from '../../lib/contexts';

function TopBarAndFooter({ className = '', showNumber = false }) {
  const { postal, country } = useGeoLocationContext();

  const onClickPhoneNumber = () => {
    window.open(`tel:${LOCAL_PHONE_NUMBER}`, '_blank');
  };

  return (
    <div className={`top-bar-and-footer ${className}`}>
      <div className="top-bar-and-footer__container">
        <a href="/" className="top-bar-and-footer__link">
          <img src={GWTLogo} alt="Logo" className="top-bar-and-footer__image" />
        </a>
        {/*showELocalPhone(postal?.code, country?.iso_code) && showNumber && (
          <div
            onClick={onClickPhoneNumber}
            className="top-bar-and-footer__number-container"
          >
            {"Don't Wait - Call us now!"}
            <span className="top-bar-and-footer__number-text">
              {LOCAL_PHONE_NUMBER}
            </span>
          </div>
        )*/}
      </div>
    </div>
  );
}

export default TopBarAndFooter;
