import {
  HOMEOWNER_OPTIONS,
  RADIO_ERROR_MESSAGE,
  ZIP_CODE_ERROR_MESSAGE,
  ZIP_CODE_REGEX,
} from '../../../lib/constants';
import {
  debounceUpdate,
  handleKeyDown,
  isZipCodeValid,
  onlyNumbersInput,
} from '../../../lib/utils';
import { useEffect, useRef, useState } from 'react';

import { ThreeDots } from 'react-loader-spinner';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep1() {
  const [isValidatingZip, setIsValidatingZip] = useState(false);
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    formState,
    setError,
    clearErrors,
  } = useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;
  const homeownerRef = useRef();
  const zipRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    if (event.target.type !== 'radio') {
      const input = event.currentTarget.querySelector('input[type="radio"]');
      input.click();
    }
  };

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['homeowner', 'zip']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        homeowner: homeownerRef,
        zip: zipRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  const handleUpdateZipCode = async (data) => {
    setIsValidatingZip(true);
    const { error, ...resultData } = await isZipCodeValid(data.zip);
    updateFormData(resultData);

    if (error) {
      setError('zip', { message: ZIP_CODE_ERROR_MESSAGE });
    } else {
      clearErrors('zip');
      setValue('city', resultData.city);
      setValue('state', resultData.state);
      setValue('zip', resultData.zip);
    }
    setIsValidatingZip(false);
  };

  useScrollToTop();
  return (
    <div className="form-step">
      <div className="form-step__label">Do you own your home?</div>
      <div className="form-step__input-container">
        {HOMEOWNER_OPTIONS.map(
          ({ id, value, textOption, registerLabel }, index) => (
            <div
              key={`${id}-${index}`}
              className={`form-step__input form-step__input-no-background ${
                errors[registerLabel] && 'form-step__error'
              }`}
              onClick={handleClick}
            >
              <div className="form-step__input-radio-wrapper">
                <input
                  type="radio"
                  value={value}
                  id={id}
                  ref={homeownerRef}
                  {...register(registerLabel, { required: true })}
                  onChange={(e) => {
                    updateFormData({ homeowner: e.target.value });
                    setValue(registerLabel, e.target.value);
                  }}
                />
                <label htmlFor={id}>{textOption}</label>
              </div>
            </div>
          )
        )}
        {errors.homeowner && (
          <div className="form-step__error-message">{RADIO_ERROR_MESSAGE}</div>
        )}
      </div>

      <div className="form-step__label form-step__label-last">
        What is the property zip code?
      </div>
      <div className="form-step__input-container">
        <input
          type="text"
          placeholder="Please enter ZIP code"
          ref={zipRef}
          onKeyDown={handleKeyDown}
          className={`form-step__input ${
            errors.zip && 'form-step__input-error'
          }`}
          maxLength="5"
          inputMode="numeric"
          onInput={onlyNumbersInput}
          autoComplete="off"
          {...register('zip', {
            required: ZIP_CODE_ERROR_MESSAGE,
            pattern: {
              value: ZIP_CODE_REGEX,
            },
            validate: () => {
              if (formData.city === '') return ZIP_CODE_ERROR_MESSAGE;
            },
          })}
          onChange={(e) =>
            debounceUpdate('zip', e.target.value, handleUpdateZipCode)
          }
        />
      </div>

      {isValidatingZip ? (
        <ThreeDots
          wrapperStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
          visible={true}
        />
      ) : formData.homeowner === 'yes' ? (
        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          disabled={Object.keys(errors).length > 0}
          type="button"
          value="Continue"
        />
      ) : (
        <div className="form-step__error-text-label">
          Unfortunately, you must own your home to qualify for this windows
          program.
        </div>
      )}
    </div>
  );
}

export default FormStep1;
