import debounce from 'lodash/debounce';

export const debounceUpdate = debounce((key, value, onChange) => {
  onChange({ [key]: value });
}, 500);

export const handleKeyDown = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
  }
};

export const submitForm = async (data) => {
  const trustedformtoken = `${process.env.REACT_APP_TRUSTED_FORM_API}/${window.trustedFormId}`;
  const leadidtoken = window.leadId || '';
  const anura = sessionStorage.getItem('anura_final_response');
  const clickid = window.clickId || '';
  /*const tcpaLabel = document.querySelector(
    'label[for="leadid_tcpa_disclosure"]'
  );
  const tcpa = tcpaLabel.textContent;*/
  const source_url = window.location.origin + window.location.pathname;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
      trustedformtoken,
      leadidtoken,
      anura,
      clickid,
      //tcpa,
      source_url,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lead/webprematch`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const result = await response.json();

    return { error: false, ...result };
  } catch (error) {
    return { error: true, ...error };
  }
};

export const submitPostMatchForm = async (data) => {

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lead/webonetoone`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const result = await response.json();

    return { error: false, ...result };
  } catch (error) {
    return { error: true, ...error };
  }
};
