import { useEffect, useState } from 'react';
import { useDisclaimerContext } from '../../../../lib/contexts';
import { CustomModalContent } from '../../../CustomComponents';

function DesignCheckbox({lenderList, selectedList, onSelect, onSubmit, phone, firstOneToOneSubmit, selectAll}) {
  const [error, setError] = useState(false)

  const { disclaimerContext } = useDisclaimerContext();
  const { partnerList, termsOfUse, privacyPolicy, arbitrate } = disclaimerContext;

  const [isOpen, setIsOpen] = useState({
    partnerList: false,
    termsOfUse: false,
    privacyPolicy: false,
    arbitrate: false,
  });

  const openModal = (key) => setIsOpen({ ...isOpen, [key]: true });
  const closeModal = (key) => setIsOpen({ ...isOpen, [key]: false });

  const click = (event) => {
    if (event.target?.id === 'ppTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: false,
        privacyPolicy: true,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.target?.id === 'touTag') {
      setIsOpen({
        partnerList: false,
        termsOfUse: true,
        privacyPolicy: false,
        arbitrate: false,
      });
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const submitList = () => {
    if (!selectedList.length) {
      setError(true);
    }else {
      setError(false);
      onSubmit();
    }
  }

  useEffect(() => {
    if (lenderList.length === 1) {
      onSelect({target: {checked:true, value: lenderList[0].name}});
    }else {
      selectAll(lenderList.map(item => item.name));
    }
  }, [lenderList]);

  return (
    <div className="form-step3">
      {JSON.stringify(selectedList)}
      <CustomModalContent
        title="Partner List"
        isOpen={isOpen.partnerList}
        onClose={() => closeModal('partnerList')}
        content={partnerList.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Terms Of Use"
        isOpen={isOpen.termsOfUse}
        onClose={() => closeModal('termsOfUse')}
        content={termsOfUse.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Privacy Policy"
        isOpen={isOpen.privacyPolicy}
        onClose={() => closeModal('privacyPolicy')}
        content={privacyPolicy.data?.html}
        clickEvent={click}
        isHtml
      />
      <CustomModalContent
        title="Arbitrate"
        isOpen={isOpen.arbitrate}
        onClose={() => closeModal('arbitrate')}
        content={arbitrate.data?.html}
        clickEvent={click}
        isHtml
      />
      <form data-tf-element-role="offer">
        <div className="form-step__label">You're Almost There!</div>
        {lenderList.length === 1 ?
          <div className="form-step">
            {!firstOneToOneSubmit &&
              <div style={{ fontSize: '13px', marginTop: '10px', color: '#777777', marginBottom: '10px' }}>
                We’re sorry, but the partners you selected couldn't assist with your request. Here are some additional
                partners that are ready to assist you!
              </div>
            }
            <label htmlFor="leadid_tcpa_disclosure">
              <div style={{ fontSize: '13px', marginTop: '10px', color: '#777777', marginBottom: '10px' }}>
                <input type="hidden" id="leadid_tcpa_disclosure" />
                By clicking "<span data-tf-element-role="submit-text">Get Your Matches</span>" I am signing and
          expressly
          consenting that <span data-tf-element-role="consent-advertiser-name"><label><input type="hidden" id="leadid_tcpa_disclosure_1"/>{lenderList[0].name}</label>
      </span> may
      contact me
      for marketing purposes at <span data-tf-element-role="consent-grantor-phone">{phone}</span>, including with an automated telephone dialing system,
                pre-recorded or artificial voice, and I agree to be contacted by these <a
                className="custom-submit__button-as-link"
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('partnerList');
                }}
              >
                companies
              </a> <span data-tf-element-role="consent-grantor-waived-dnc">even if my telephone number is on a state, federal or corporate Do Not Call list</span>;
                and I agree to the <a
                  href="#"
                  className="custom-submit__button-as-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal('privacyPolicy');
                  }}
                >
                  Privacy Policy
                </a>, <a
                href="#"
                className="custom-submit__button-as-link"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('termsOfUse');
                }}
              >
                Terms of Use
              </a>, and to <a
                href="#"
                className="custom-submit__button-as-link"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('arbitrate');
                }}
              >
                Arbitrate
              </a> all disputes, and to sharing of my personal data with
                third-parties. I understand my consent is not a condition of receiving services and that I may instead
                email consent@rgrmarketing.com.
              </div>
              <button type="button" onClick={onSubmit} className="custom-submit__btn" data-tf-element-role="submit">
                Get Your Matches
              </button>
            </label>
          </div>
          :
          <div className="form-step">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {!firstOneToOneSubmit &&
              <div style={{ fontSize: '13px', marginTop: '10px', color: '#777777', marginBottom: '10px' }}>
                We’re sorry, but the partners you selected couldn't assist with your request. Here are some additional partners that are ready to assist you!
              </div>
            }
            <label htmlFor="leadid_tcpa_disclosure">
              <div style={{ fontSize: '13px', marginTop: '10px', color: '#777777' }}>
                <input type="hidden" id="leadid_tcpa_disclosure" />
                By clicking "<span data-tf-element-role="submit-text">Get Your Matches</span>" I am signing and expressly
                consenting that the company or companies
                selected
                below, may contact me for marketing purposes at <span
                data-tf-element-role="consent-grantor-phone">{phone}</span>, including with an automated
                telephone
                dialing system, pre-recorded or artificial voice, and I agree to be contacted by these <a
                className="custom-submit__button-as-link"
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('partnerList');
                }}
              >
                companies
              </a> <span data-tf-element-role="consent-grantor-waived-dnc">even if my
                telephone number is on a state, federal or corporate Do Not Call list</span>; and I agree to the <a
                href="#"
                className="custom-submit__button-as-link"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('privacyPolicy');
                }}
              >
                Privacy Policy
              </a>, <a
                  href="#"
                  className="custom-submit__button-as-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    openModal('termsOfUse');
                  }}
                >
                  Terms of Use
                </a>, and to <a
                href="#"
                className="custom-submit__button-as-link"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('arbitrate');
                }}
              >
                Arbitrate
              </a> all disputes, and to sharing of my personal data with third-parties.
              </div>
            </label>
              <div
                style={{
                  marginTop: '10px',
                  display: 'grid',
                  placeContent: 'start',
                  marginBottom: '10px',
                }}
              >
                {lenderList.map((item, key) => (
                  <label className="labelDesign1" key={key} id={`leadid_tcpa_disclosure_${key+1}`} data-tf-element-role={`consent-opted-advertiser-name-${key+1}`}>
                    <input
                      data-tf-element-role={`consent-opted-advertiser-input-${key+1}`}
                      type="checkbox"
                      className="checkboxDesign1"
                      checked={selectedList.includes(item.name)}
                      value={item.name}
                      onChange={(e) => {
                        setError(false);
                        onSelect(e);
                      }}
                    />
                    {item.name}
                  </label>
                ))}
              </div>
              <div style={{ fontSize: '16px', marginTop: '5px', marginBottom: '5px' }}>
                Keep all options selected for the most quotes. I understand my consent is not a condition of receiving
                services and that I may instead email consent@rgrmarketing.com
              </div>
              {error &&
                <div style={{ marginTop: '5px', marginBottom: '5px', color: 'red' }}>
                  You must select a minimum of one partner to get your quote. Selecting all partners is recommended.
                </div>
              }
              <button type="button" onClick={submitList} className="custom-submit__btn" data-tf-element-role="submit">
                Get Your Matches
              </button>
          </div>
        }
      </form>
    </div>
  );
}

export default DesignCheckbox;
