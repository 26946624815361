import {
  PROJECT_TYPE_ERROR_MESSAGE,
  WINDOWS_COUNT_ERROR_MESSAGE,
  WINDOWS_COUNT_OPTIONS,
  WINDOWS_OPTIONS,
} from '../../../lib/constants';
import { useEffect, useRef } from 'react';

import { CustomSelect } from '../../CustomComponents';
import ReactGA from 'react-ga4';
import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep2() {
  const { register, trigger, handleSubmit, clearErrors, formState } =
    useFormContext();
  const { updateStep, updateFormData, formData } = useFormDataContext();
  const { errors } = formState;
  const projectTypeRef = useRef();
  const windowCountRef = useRef();

  const handleClickContinue = handleSubmit(async (_data) => {
    const isValid = await trigger(['projecttype', 'windowcount']);

    if (!isValid) {
      const [firstErrorKey] = Object.keys(errors);
      const errorRefs = {
        projecttype: projectTypeRef,
        windowcount: windowCountRef,
      };

      errorRefs[firstErrorKey]?.current?.focus();
    } else {
      updateStep(formData.step + 1);
    }
  });

  useEffect(() => {
    ReactGA.event({
      category: 'GWT Landing',
      action: 'GWT Step 2 - Windows Info',
      label: 'GWT Landing',
    });
  }, []);

  useScrollToTop();
  return (
    <div className="form-step2">
      <div className="form-step">
        <div className="form-step__label">
          Are you looking to replace or repair existing windows?
        </div>

        <CustomSelect
          options={WINDOWS_OPTIONS}
          name="projecttype"
          value={formData.projecttype}
          register={register}
          onChange={updateFormData}
          errorMessage={PROJECT_TYPE_ERROR_MESSAGE}
          errors={errors}
          customRef={projectTypeRef}
        />

        <div className="form-step__label">
          How many windows for this project?
        </div>

        <CustomSelect
          options={WINDOWS_COUNT_OPTIONS}
          name="windowcount"
          value={formData.windowcount}
          register={register}
          errorMessage={WINDOWS_COUNT_ERROR_MESSAGE}
          onChange={updateFormData}
          errors={errors}
          customRef={windowCountRef}
        />

        <input
          onClick={handleClickContinue}
          className="form-step__btn"
          type="button"
          value="Continue"
        />

        <input
          onClick={() => {
            clearErrors();
            updateStep(formData.step - 1);
          }}
          className="form-step__btn-back"
          type="button"
          value="Back"
        />
      </div>
    </div>
  );
}

export default FormStep2;
